import React, { ReactElement } from "react"
import { Fragment } from "react"
import { Menu, Transition } from "@headlessui/react"
import ChevronDownIcon from "@heroicons/react/20/solid/ChevronDownIcon"
import { FormattedMessage } from "../../../FormattedMessage/FormattedMessage"
import { connector, ContainerProps } from "./containers/Dropdown.container"
import { IStatsRepositoryPeriod } from "../../../../interfaces/IStatsRepository"

const Item: React.FC<{ onClick: () => void; children: ReactElement }> = (
  props
) => (
  <Menu.Item>
    <div
      onClick={props.onClick}
      className={
        "text-slate-700   block px-4 py-2 text-sm cursor-pointer hover:bg-slate-50  :bg-black"
      }
    >
      {props.children}
    </div>
  </Menu.Item>
)

const Wrapper: React.FC<{
  period: IStatsRepositoryPeriod
  onSelect: (period: IStatsRepositoryPeriod) => any
}> = (props) => {
  return (
    <Menu as="div" className="relative z-10 block text-left font-display">
      <div>
        <Menu.Button className="flex justify-center w-full px-4 py-2 text-sm font-medium text-slate-700 bg-white rounded-md border border-slate-200hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-slate-100 focus:ring-blue-500">
          <FormattedMessage id={`stats.date-dropdown.${props.period}`} />
          <ChevronDownIcon className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg   ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Item onClick={() => props.onSelect("week")}>
              <FormattedMessage id={`stats.date-dropdown.week`} />
            </Item>
            <Item onClick={() => props.onSelect("month")}>
              <FormattedMessage id={`stats.date-dropdown.month`} />
            </Item>
            <Item onClick={() => props.onSelect("year")}>
              <FormattedMessage id={`stats.date-dropdown.year`} />
            </Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const Dropdown = connector(Container)
