import { connect, ConnectedProps } from "react-redux"
import { IStatsRepositoryPeriod } from "../../../../../interfaces/IStatsRepository"
import { actions } from "../../../../../redux/actions"
import { RootState } from "../../../../../redux/store"

const mapState = (state: RootState) => ({
  period: state.stats.period,
})

const mapDispatch = (dispatch: any) => ({
  onSelect(period: IStatsRepositoryPeriod) {
    dispatch(actions.stats.$changePeriod({ period }))
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
