import clsx from "clsx"
import React, { useEffect } from "react"
import {
  StatByLevelDisplayable,
  StatDisplayable,
  StatTotalDisplayable,
} from "../../entities/StatEntity"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { MarketplaceSubscribeCTA } from "../MarketplaceSubscribeCTA/MarketplaceSubscribeCTA"
import { PageHeading } from "../PageHeading/PageHeading"
import { Dropdown } from "./components/Dropdown/Dropdown"
import { StatsByLevel } from "./components/StatsByLevel"
import { TimeSeriesGraph } from "./components/TimeSeriesGraph/TimeSeriesGraph"
import { connector, ContainerProps } from "./containers/Stats.container"

export const Wrapper: React.FC<{
  onMount: Function
  byLevel: { [key: string]: StatByLevelDisplayable }
  isPremium: boolean
}> = (props) => {
  useEffect(() => {
    props.onMount()
  }, [])

  return (
    <>
      <PageHeading
        title={<FormattedMessage id="stats/title" />}
        description={<FormattedMessage id="stats/description" />}
      />

      <div className="relative px-2">
        <div className={clsx("max-w-3xl pb-8 mx-auto blur-sm")}>
          <div className="py-2 sm:py-3">
            <div className="flex items-center w-full">
              <div className="flex ml-auto">
                <Dropdown />
              </div>
            </div>
          </div>

          <TimeSeriesGraph />

          <div className="grid w-full gap-x-2 md:grid-cols-2 md:flex">
            <StatsByLevel
              attribute="games"
              easy={props.byLevel.easy}
              medium={props.byLevel.medium}
              hard={props.byLevel.hard}
              expert={props.byLevel.expert}
              evil={props.byLevel.evil}
            />

            <StatsByLevel
              attribute="duration"
              easy={props.byLevel.easy}
              medium={props.byLevel.medium}
              hard={props.byLevel.hard}
              expert={props.byLevel.expert}
              evil={props.byLevel.evil}
            />
          </div>
        </div>

        <div className="max-w-4xl mx-auto flex justify-center absolute z-10 inset-0">
          <MarketplaceSubscribeCTA />
        </div>
      </div>
    </>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const Stats = connector(Container)
