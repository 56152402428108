import React from "react"
import {
  connector,
  ContainerProps,
} from "./containers/MarketplaceSubscribeCTA.container"
import { navigate } from "gatsby"
import { SUBSCRIPTION_MODAL_HASH } from "../../utils/drawerUtils"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"

type Props = {
  subscribed: boolean
}

export const Wrapper: React.FC<Props> = (props) => {
  return props.subscribed ? (
    <></>
  ) : (
    <div className="px-4">
      <div className="md:flex p-4 md:p-10 md:py-14 py-6 relative bg-slate-900 overflow-hidden bg-gradient-to-t from-slate-900 to-violet-900 shadow-xl  w-full rounded-2xl items-center">
        <div
          className="absolute top-0 bottom-0 left-0 right-0 z-10 w-full transform -translate-x-1/2 -translate-y-20 bg-white rounded-br-full pointer-events-none select-none opacity-5 rotate-12"
          style={{ height: "300px" }}
        ></div>

        <img
          src="/marketplace/sparkle.svg"
          width="20px"
          height="20px"
          className="absolute left-4 top-4 animate-pulse animation-delay-1000"
        />

        <img
          src="/marketplace/sparkle.svg"
          width="24px"
          height="24px"
          className="absolute right-4 top-10 animate-pulse animation-delay-2000"
        />

        <img
          src="/marketplace/sparkle.svg"
          width="20px"
          height="20px"
          className="absolute right-8 bottom-6 animate-pulse animation-delay-1000"
        />

        <img
          src="/marketplace/sparkle.svg"
          width="20px"
          height="20px"
          className="absolute top-6 left-96 animate-pulse animation-delay-2000"
        />
        <img
          src="/marketplace/sparkle.svg"
          width="24px"
          height="24px"
          className="absolute bottom-6 right-80 animate-pulse animation-delay-1000"
        />

        <img
          src="/marketplace/sparkle.svg"
          width="24px"
          height="24px"
          className="absolute left-8 bottom-5 animate-pulse animation-delay-2000"
        />

        <img
          src="/marketplace/sparkle.svg"
          width="24px"
          height="24px"
          className="absolute left-60 bottom-8 animate-pulse animation-delay-1000"
        />

        <div className="rounded flex-shrink-0 relative">
          <img
            width={"96px"}
            height={"96px"}
            className="w-24 h-24 mx-auto rounded"
            src={"/chest/ultimate/open.svg"}
          />
        </div>
        <div className="md:px-4 text-center md:text-left mt-2 md:mt-0 relative">
          <p className="font-display text-white font-semibold text-xl">
            <FormattedMessage id="premium/call-to-action/title" />
          </p>
          <p className=" text-slate-50">
            <FormattedMessage id="premium/call-to-action/description" />
          </p>
        </div>
        <div className="flex-shrink-0 ml-auto flex justify-center md:mt-0 mt-4 relative">
          <button
            onClick={() => {
              navigate(location.pathname + "#" + SUBSCRIPTION_MODAL_HASH)
            }}
            className="bg-white rounded-xl transition-all ease-in-out duration-300 font-display font-semibold p-4 shadow-btn-2 text-violet-500 shadow-violet-500 border-2 border-violet-500"
          >
            <FormattedMessage id="premium/call-to-action/more" />
          </button>
        </div>
      </div>
    </div>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const MarketplaceSubscribeCTA = connector(Container)
