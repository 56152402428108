import React, { useEffect } from "react"
import {
  StatDisplayable,
  StatTotalDisplayable,
} from "../../../../entities/StatEntity"
import { secondsToHumanString } from "../../../../utils/date"
import { FormattedMessage } from "../../../FormattedMessage/FormattedMessage"
import { LineChart } from "../LineChart"
import {
  connector,
  ContainerProps,
} from "./containers/TimeSeriesGraph.container"

export const Wrapper: React.FC<{
  stats: StatDisplayable[]
  dark: boolean
  total: StatTotalDisplayable
  onMount: () => void
}> = (props) => {
  useEffect(() => {
    props.onMount()
  }, [])

  return (
    <div className="w-full bg-white rounded border relative border-slate-200">
      <div className="grid grid-cols-2">
        <div className="w-full px-8 my-4 border-r md:border-r-0">
          <div className="text-xs font-bold tracking-wide text-slate-500 uppercase font-display whitespace-nowrap">
            <FormattedMessage id="stats.total.games.title" />
          </div>
          <div className="flex items-center justify-between my-1 whitespace-nowrap">
            <b className="mr-4 text-xl md:text-2xl  ">{props.total.games}</b>
          </div>
        </div>
        <div className="w-full px-8 my-4 border-slate-300 md:border-l">
          <div className="text-xs font-bold tracking-wide text-slate-500 uppercase   whitespace-nowrap">
            <FormattedMessage id="stats.total.duration.title" />
          </div>
          <div className="flex items-center justify-between my-1 whitespace-nowrap">
            <b className="mr-4 text-xl md:text-2xl  ">
              {secondsToHumanString(props.total.duration)}
            </b>
          </div>
        </div>
      </div>

      <LineChart data={props.stats} dark={props.dark} />
    </div>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const TimeSeriesGraph = connector(Container)
