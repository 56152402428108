import React from "react"
import { ReactElement } from "react-markdown/lib/react-markdown"

export const PageHeading: React.FC<{
  title: ReactElement | string
  description: ReactElement | string
  separator?: boolean
}> = (props) => (
  <section className="py-8 md:pt-14 relative">
    <div className="container mx-auto">
      <div className="md:max-w-3xl mx-auto mb-4 ">
        <h1 className="mb-4 text-3xl px-4  md:text-5xl text-center font-display leading-tight text-slate-900 font-bold tracking-tighter">
          {props.title}
        </h1>
        <p className="mb-4 text-lg px-4  md:text-xl text-center text-slate-900">
          {props.description}
        </p>

        {props.separator && (
          <div className="px-4">
            <div className="border-t border-slate-100 mt-10" />
          </div>
        )}
      </div>
    </div>
  </section>
)
