import React from "react"
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  AreaChart,
  Area,
  ResponsiveContainer,
} from "recharts"
import { StatDisplayable } from "../../../entities/StatEntity"
import { secondsToHumanString } from "../../../utils/date"

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload

    return (
      <div className="px-8 py-4 bg-white rounded shadow-md">
        <p className="text-xs font-medium text-center text-slate-900 uppercase">
          {label}
        </p>

        <p className="mt-2 font-medium text-center text-blue-500">{`${secondsToHumanString(
          data.duration
        )}`}</p>
      </div>
    )
  }

  return null
}

export const LineChart: React.FC<{ data: StatDisplayable[]; dark: boolean }> = (
  props
) => {
  return (
    <ResponsiveContainer aspect={2} width="100%">
      <AreaChart data={props.data} margin={{ left: 20, right: 20, bottom: 20 }}>
        <defs>
          <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="1%" stopColor="#3B82F6" stopOpacity={0.7} />
            <stop offset="99%" stopColor="#3B82F6" stopOpacity={0} />
          </linearGradient>
        </defs>

        <CartesianGrid
          strokeDasharray="10 10"
          opacity={props.dark ? 0.1 : 0.5}
        />

        <XAxis
          interval={"preserveEnd"}
          dataKey="date"
          tickLine={false}
          style={{ fontSize: "12px" }}
          tickMargin={0}
          axisLine={false}
          opacity={props.dark ? 1 : 0.5}
          mirror
        />

        <YAxis
          axisLine={false}
          tickLine={false}
          style={{ fontSize: "12px" }}
          opacity={props.dark ? 1 : 0.5}
          mirror
          interval={"preserveEnd"}
        />

        <Tooltip content={CustomTooltip} />

        <Area
          type="linear"
          dot={true}
          dataKey="duration"
          stroke="#3B82F6"
          strokeWidth={3}
          fill="url(#gradient)"
          isAnimationActive={false}
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}
