import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../../../redux/actions"
import { RootState } from "../../../../../redux/store"

const mapState = (state: RootState) => ({
  stats: state.stats.stats,
  total: state.stats.total,
  dark: state.darkMode.dark,
})

const mapDispatch = (dispatch: any) => ({
  onMount: () => {
    dispatch(actions.auth.$isAuthenticated()).then(() => {
      dispatch(actions.stats.$findAll())
    })
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
