import React from "react"
import { StatByLevelDisplayable } from "../../../entities/StatEntity"
import { secondsToHumanString } from "../../../utils/date"
import { FormattedMessage } from "../../FormattedMessage/FormattedMessage"

const getLevelColor = (level: string) => {
  if (level === "easy") return "bg-blue-500"
  if (level === "medium") return "bg-yellow-500"
  if (level === "hard") return "bg-red-500"
  if (level === "expert") return "bg-slate-500"
  if (level === "evil") return "bg-slate-900  "

  return ""
}

const Level: React.FC<{ level: string; value: string | number }> = (props) => {
  return (
    <div className="flex items-center justify-between my-1 text-sm">
      <div className="relative w-full">
        <div className="relative flex items-center p-2  ">
          <div
            className={`w-4 h-4 mr-2 -mt-px ${getLevelColor(
              props.level
            )} rounded-full`}
          />
          <FormattedMessage id={`levels.${props.level}`} />
        </div>
      </div>
      <span className="flex-shrink-0 w-20 font-medium text-right  ">
        {props.value}
      </span>
    </div>
  )
}

export const StatsByLevel: React.FC<{
  easy: StatByLevelDisplayable
  medium: StatByLevelDisplayable
  hard: StatByLevelDisplayable
  expert: StatByLevelDisplayable
  evil: StatByLevelDisplayable
  attribute: "games" | "duration"
}> = (props) => (
  <div className="relative flex flex-col w-full p-4 mt-6 bg-white rounded border border-slate-200 stats-item">
    <div className="flex flex-col flex-grow font-display">
      <div id="sources" className="flex justify-between w-full">
        <div className="font-bold">
          {props.attribute === "duration" && (
            <FormattedMessage id="stats.per-level.duration.title" />
          )}
          {props.attribute === "games" && (
            <FormattedMessage id="stats.per-level.games.title" />
          )}
        </div>
      </div>
      <div className="flex flex-col flex-grow fade-enter-active">
        <div className="flex items-center justify-between mt-3 mb-2 text-xs font-bold tracking-wide text-slate-500">
          <FormattedMessage id="stats.levels.title" />
          <div className="text-right">
            <span className="inline-block w-20">
              {props.attribute === "duration" && (
                <FormattedMessage id="stats.duration.title" />
              )}

              {props.attribute === "games" && (
                <FormattedMessage id="stats.games.title" />
              )}
            </span>
          </div>
        </div>
        <div className="relative flex-grow">
          <Level
            level="easy"
            value={
              props.attribute === "games"
                ? props.easy[props.attribute]
                : secondsToHumanString(props.easy[props.attribute])
            }
          />

          <Level
            level="medium"
            value={
              props.attribute === "games"
                ? props.medium[props.attribute]
                : secondsToHumanString(props.medium[props.attribute])
            }
          />

          <Level
            level="hard"
            value={
              props.attribute === "games"
                ? props.hard[props.attribute]
                : secondsToHumanString(props.hard[props.attribute])
            }
          />

          <Level
            level="expert"
            value={
              props.attribute === "games"
                ? props.expert[props.attribute]
                : secondsToHumanString(props.expert[props.attribute])
            }
          />

          <Level
            level="evil"
            value={
              props.attribute === "games"
                ? props.evil[props.attribute]
                : secondsToHumanString(props.evil[props.attribute])
            }
          />
        </div>
      </div>
    </div>
  </div>
)
